import {
    ItemBag,
    NoCost,
    Coins,
    Innovation,
    HybridWork,
    HoldingDollar,
} from './assets/images';

import { colors } from './styles/variables';

import {
    heroRef,
    howItWorksRef,
    benefitsRef,
    faqRef,
    opinionsRef,
    contactRef,
} from './utils/sectionRefs';

export const PN4_HERO_CONFIG = {
    title: "Kup teraz, zapłać później w 4 ratach 0%",
    id: "section-hero",
    forwardedRef: heroRef.forwardedRef,
    navigationRef: heroRef.navigationRef,
    subtitle: "",
    content: [
        {
            text: "Odkryj nowe możliwości z PayPo i daj sobie czas na płatność. Kupujesz teraz, a płacisz później - w 4 ratach 0%. Pierwszą z nich opłacasz dopiero za 30 dni od zakupu, a następne w kolejnych miesiącach, bez dodatkowych kosztów. Twoje zakupy z PayPo będą jeszcze bardziej Smart.",
        },
    ],
    heroGraphic: "pn4",
};

export const PN4_ORDERED_ITEMS_CONFIG = {
    title: "Jak to działa?",
    id: "section-how-it-works",
    forwardedRef: howItWorksRef.forwardedRef,
    navigationRef: howItWorksRef.navigationRef,
    itemList: [
        {
            id: 1,
            title: "Krok 1",
            text: "Finalizując zakupy w sklepie wybierasz usługę PayPo “Podziel na 4”, jako formę płatności za zamówienie.",
        },
        {
            id: 2,
            title: "Krok 2",
            text: "Przy pierwszych zakupach podajesz swoje podstawowe dane i zatwierdzasz płatność kodem SMS lub w aplikacji.",
        },
        {
            id: 3,
            title: "Krok 3",
            text: "PayPo opłaca Twoje zakupy, a ty otrzymujesz produkty, sprawdzasz je i decydujesz o tym, czy je wszystkie zatrzymasz.",
        },
        {
            id: 4,
            title: "Krok 4",
            text: "Twoja płatność jest automatycznie podzielona na 4 raty 0%. Pierwszą z nich opłacasz w ciągu 30 dni od zakupów, a pozostałe w kolejnych miesiącach.",
        }
    ],
    squares: [
        {
            top: "11.5rem",
            left: "-17rem",
            size: "20rem",
            bgColor: colors.paypoGreen500,
            rotate: "45deg",
        }
    ],
};

export const PN4_VIDEO_CONTAINER_CONFIG = {
    videoId: "l9_QL8jGZxk",
    squares: [
        {
            top: "6rem",
            left: "7.2rem",
            size: "4.8rem",
            bgColor: colors.fuchsia500,
            rotate: "81.39deg",
        },
        {
            top: "33rem",
            right: "7.2rem",
            size: "4.8rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            top: "11rem",
            right: "-15.5rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const PN4_ICON_ITEMS_CONFIG = {
    title: "Korzyści",
    id: "section-benefits",
    forwardedRef: benefitsRef.forwardedRef,
    navigationRef: benefitsRef.navigationRef,
    content: [
        {
            Icon: NoCost,
            description: '<b>Całkowicie za darmo</b> - płatność dzielisz na wygodne, nieoprocentowane raty i nie ponosisz żadnych dodatkowych kosztów z tym związanych',
        },
        {
            Icon: Coins,
            description: '<b>Nie płacisz jednorazowo, ale w wygodnych częściach</b> - na spłatę pierwszej masz aż 30 dni od zakupu, a pozostałe opłacasz w kolejnych miesiącach',
        },
        {
            Icon: ItemBag,
            description: '<b>Robisz pewne zakupy</b> - otrzymujesz zamówienie, sprawdzisz je i płacisz tylko za to, co zdecydujesz się zatrzymać',
        },
        {
            Icon: Innovation,
            description: '<b>Kupujesz SMART</b> - możesz spokojnie robić większe i droższe zakupy, nie nadwyrężając swojego budżetu i rozciągając płatność za darmo w czasie',
        },
        {
            Icon: HybridWork,
            description: '<b>Kupujesz wygodnie też w sklepach stacjonarnych</b>, nie tylko online, bierzesz zakupy do domu i płacisz później w ratach 0%',
        },
        {
            Icon: HoldingDollar,
            description: '<b>Płacisz elastycznie w 4 ratach</b> - płatność zatem rozciągasz wygodnie w czasie',
        },
    ],
    additionalText: [
        {
            text: 'Usługa Podziel na 4 dostępna w wybranych sklepach. Informacja nie stanowi oferty. Szczegóły w Regulaminie usługi SmartPlan dostępnym na www.paypo.pl.'
        },
    ],
    squares: [
        {
            bottom: "-14rem",
            right: "-13rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const PN4_ACCORDION_SECTION = {
    title: "Najczęściej zadawane pytania",
    id: "section-faq",
    forwardedRef: faqRef.forwardedRef,
    navigationRef: faqRef.navigationRef,
    content: [
        {
            id: "1",
            title: "1. Czy muszę podawać dane swojej karty kredytowej/debetowej?",
            text: "Nie, do skorzystania z płatności PayPo nie wymagamy podania danych karty kredytowej/debetowej. ",
            isExpanded: true,
        },
        {
            id: "2",
            title: "2. W jaki sposób spłacę zamówienie w PayPo?",
            text: "Możesz zapłacić przelewem bankowym, przekazem pocztowym lub szybkimi płatnościami online - jak Ci wygodniej. Numer konta do wpłaty otrzymasz mailem wraz z potwierdzeniem złożenia zamówienia. Dla Twojej wygody udostępniamy panel Klienta - nie masz obowiązku logować się do niego, ale możesz. Tam masz bieżący podgląd wszystkich ważnych informacji.",
        },
        {
            id: "3",
            title: "3. Czy to bezpieczne?",
            text: "Tak, wszystkie płatności są realizowane w ramach szyfrowanego połączenia, a Ty nie podajesz żadnych danych dostępowych do swojego konta.",
        },
        {
            id: "4",
            title: "4. Czy to coś kosztuje?",
            text: "Nie, korzystając z usługi SmartPlan z ratami 0% spłacasz tylko tyle, ile wynosi wartość zamówienia, bez dodatkowych kosztów.",
        },
        {
            id: "5",
            title: "5. Do kiedy muszę spłacić transakcję?",
            text: "Masz 30 dni na spłatę pierwszej raty, a kolejne opłacasz w następnych miesiącach według harmonogramu, który otrzymujesz od nas w mailu i możesz go sprawdzić w Panelu Klienta. O zbliżającym się terminie spłaty każdej raty przypomnimy Ci za pośrednictwem SMS i mailem.",
        },
        {
            id: "6",
            title: "6. Co się stanie, jeśli zwrócę produkt?",
            text: "Twoje zobowiązanie zostanie zmniejszone o wartość produktu, który zwróciłeś.",
        },
    ],
    squares: [
        {
            top: "54rem",
            left: "13rem",
            size: "6rem",
            bgColor: colors.fuchsia500,
            rotate: "78.54deg",
        },
        {
            top: "6rem",
            right: "10rem",
            size: "10rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            top: "-6rem",
            right: "-13rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const PN4_INLINE_TILES_CONFIG = {
    title: "Zobacz ponad 27000 pozytywnych opinii",
    id: "section-opinions",
    forwardedRef: opinionsRef.forwardedRef,
    navigationRef: opinionsRef.navigationRef,
    tileList: [
        {
            id: 1,
            score: '5.0',
            name: 'Monika',
            text: 'Jestem bardzo zadowolona z płatności z PayPo. W każdej chwili, kiedy czegoś potrzebuję, mogę zrobić szybko i sprawnie zakupy, a potem w dogodnym terminie dokonać spłaty.',
        },
        {
            id: 2,
            score: '5.0',
            name: 'Magdalena',
            text: 'Rewelacja - jak dla mnie najszybszy i najwspanialszy sposób zapłaty za przesyłki, które mogę spokojnie obejrzeć, przetestować, przymierzyć czy sprawdzić w domu. Bez nerwów, pośpiechu i zbędnego zamrażania gotówki. A przy tym tak pewnie i bezpiecznie. POLECAM',
        },
        {
            id: 3,
            score: '5.0',
            name: 'Ala',
            text: 'Nigdy się nie zawiodłam na usłudze PayPo. Czytelne i jasne zasady. Dobry system powiadomień. Korzystam i będę korzystać. ',
        },
    ],
    additionalText: [
        {
            text: 'Prezentowane opinie pochodzą z serwisu Opineo. Weryfikacja opinii realizowana jest zgodnie z Regulaminem dla użytkowników serwisu Opineo.pl',
        },
    ],
    cta: {
        type: "button",
        text: "Zobacz opinie na Opineo",
        link: "https://www.opineo.pl/opinie/paypo-pl",
    },
    squares: [
        {
            bottom: "18rem",
            left: "-8rem",
            size: "10rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            bottom: "-4.8rem",
            left: "-12rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
        {
            top: "24.8rem",
            right: "31rem",
            size: "14rem",
            bgColor: colors.paypoGreen500,
            rotate: "69.24deg",
        },
    ],
};

export const PN4_BIG_TILES_CONFIG = {
    title: "Masz pytanie?",
    id: "section-contact",
    forwardedRef: contactRef.forwardedRef,
    navigationRef: contactRef.navigationRef,
    tileList: [
        {
            id: 1,
            bgColor: colors.mintCream,
            content: {
                title: "bok@paypo.pl",
                textContent: [
                    {
                        id: 1,
                        text: "Na większość pytań odpowiadamy w ciągu 48 godzin",
                    },
                ],
                cta: {
                    type: "button",
                    action: "email",
                    variant: "primary",
                    text: "Napisz do nas"
                }
            },
        },
        {
            id: 2,
            bgColor: colors.palePurple,
            content: {
                title: "+48 22 333 74 60",
                textContent: [
                    {
                        id: 1,
                        text: "Od poniedziałku do piątku od 8:00 do 18:00",
                    },
                    {
                        id: 2,
                        text: "Opłata za połączenie zgodna z taryfą operatora",
                    },
                ],
                cta: {
                    type: "textButton",
                    action: "phoneCall",
                    variant: "textButton",
                    text: "Zadzwoń do nas",
                },
            },
        },
    ],
    squares: [
        {
            top: "-15.2rem",
            left: "-12rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const COOKIE_MODAL_CONFIG = {
    title: "Ta strona korzysta z plików cookies",
    description: "W serwisie PayPo Sp. z o.o. wraz z naszymi partnerami korzystamy z plików cookies, aby zapewnić poprawne działanie naszego serwisu, dostosowywać treści do Twoich preferencji i śledzić statystyki. Możesz wyrazić zgodę na wszystkie pliki cookies lub korzystać z naszego serwisu tylko z cookiesami, które są niezbędne do poprawnego działania serwisu. Szczegółowe informacje znajdziesz w",
    link: {
        text: "Polityce prywatności i cookies",
        href: "https://static.paypo.pl/documents/Polityka_prywatnosci_i_cookies.pdf",
    },
};
