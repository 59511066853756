import React, { useEffect, useRef } from 'react';

import { colors } from '../../styles/variables';

import { SectionAppearanceProvider } from '../../components/sections/SectionContext';

import Seo from '../../components/seo';
import {
    SectionHeroStart,
    SectionOrderedItems,
    SectionVideoContainer,
    SectionIconItems,
    SectionAccordion,
    SectionInlineTiles,
    SectionBigTiles,
} from '../../components/sections';

import {
    PN4_HERO_CONFIG,
    PN4_ORDERED_ITEMS_CONFIG,
    PN4_VIDEO_CONTAINER_CONFIG,
    PN4_ICON_ITEMS_CONFIG,
    PN4_ACCORDION_SECTION,
    PN4_INLINE_TILES_CONFIG,
    PN4_BIG_TILES_CONFIG,
} from '../../content_config_pn4';
import CookieModal from '../../components/organisms/CookieModal';

const IndexPn4 = () => {
    const pageBodyRef = useRef(null);

    useEffect(() => {
        const paypoDataPayload = {
            pageBodyHeight: pageBodyRef.current.clientHeight,
        };
    
        window.top.postMessage(JSON.stringify(paypoDataPayload), '*');
    }, []);
    
    return (
        <div className="page-body" ref={ pageBodyRef }>
            <SectionAppearanceProvider>
                <Seo title="Paypo - kup teraz, zapłać za 30 dni" />

                <SectionHeroStart
                    config={ PN4_HERO_CONFIG }
                />

                <SectionOrderedItems
                    config={ PN4_ORDERED_ITEMS_CONFIG }
                />

                <SectionVideoContainer
                    config={ PN4_VIDEO_CONTAINER_CONFIG }
                    backgroundBottom={ colors.grayCultured }
                />

                <SectionIconItems
                    config={ PN4_ICON_ITEMS_CONFIG }
                    background={ colors.grayCultured }
                    columns={ 3 }
                />

                <SectionAccordion
                    config={ PN4_ACCORDION_SECTION }
                    isColorSectionAbove={ true }
                />

                <SectionInlineTiles
                    config={ PN4_INLINE_TILES_CONFIG }
                />

                <SectionBigTiles
                    config={ PN4_BIG_TILES_CONFIG }
                />

                <CookieModal />
            </SectionAppearanceProvider>
        </div>
    );
};

export default IndexPn4;
